<template>
  <div>
    <div :class="[$style.title, 'login-heading']">
      <h1>
        <strong>メールアドレス変更 確認中</strong>
      </h1>
    </div>
    <div :class="$style.block"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import router from '@/router'

export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  created() {
    const _this = this
    const send = Vue.prototype.$api.send('post', 'auth/mail_confirm', {
      confirmation_token: this.$route.params.confirmation_token,
    })
    send.then(response => {
      this.$notification['success']({
        message: 'メールアドレスを変更しました。',
      })
      router.push('/')
    })
      .catch(error => {
        _this.$notification['error']({
          message: error.status + ': ' + error.data.data,
        })
        console.log(error)
      })
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
