var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: [_vm.$style.title, "login-heading"] }, [_vm._m(0)]),
    _c("div", { class: _vm.$style.block })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [_c("strong", [_vm._v("メールアドレス変更 確認中")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }